<template>
  <div>
    <v-dialog v-model="activo" persistent width="700">
      <v-card>
        <v-card-title height="15%">
            <span class="">Agregar Paquete</span>
            <v-spacer></v-spacer>
            <v-btn icon @click="activo = false">
              <v-icon>fas fa-times</v-icon>
            </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pb-0 pt-5">
          <v-form ref="formPaquetes" v-model="formPaquete">
            <v-row>
              <v-col cols="12" sm="6" md="6" class="pb-0">
                <v-text-field v-model="cliente" outlined label="Apellido y Nombre" required :rules="paquetesRules" dense></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6" class="pb-0" :class="pantallaChica ? 'pt-0' : ''">
                <v-text-field v-model="documento" outlined type="number" label="Documento" :rules="paquetesRules" dense></v-text-field>
              </v-col>
              <v-col cols="12"  sm="6" md="6" class="pb-0 pt-0">
                <v-text-field v-model="direccion" outlined label="Direccion" required :rules="paquetesRules" dense></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6" class="pb-0 pt-0">
                <v-text-field v-model="telefono" outlined label="Telefono" required :rules="paquetesRules" dense></v-text-field>
              </v-col>
              <v-col cols="12"  sm="4" md="4" class="pt-0" :class="pantallaChica ? 'pb-6' : 'pb-3'">
                <v-autocomplete 
                  v-model="selectedProvincia" 
                  :items="provincias" 
                  item-text="provincia_nombre" 
                  @change="getlocalidad()" 
                  label="Provincia" 
                  :rules="paquetesRules" 
                  dense
                  hide-details
                  return-object
                  outlined
                ></v-autocomplete>
              </v-col>
              <v-col cols="12"  sm="8" md="8" class="pb-3 pt-0">
                <v-autocomplete 
                  v-model="selectedLocalidad" 
                  :items="localidades" 
                  item-text="descripcion_larga" 
                  label="Localidad" 
                  :rules="paquetesRules" 
                  dense
                  hide-details
                  return-object
                  outlined
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" class="pb-6">
                <v-autocomplete 
                  v-model="selectedBodegaOrigen" 
                  :items="bodegasD" 
                  item-text="bodega_nombre" 
                  label="De Bodega" 
                  :rules="paquetesRules"
                  @change="controlBodegas()" 
                  dense
                  hide-details
                  return-object
                  outlined
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" class="pb-6" :class="pantallaChica ? 'pt-0' : ''">
                <v-autocomplete 
                  v-model="selectedBodegaDestino" 
                  :items="bodegasA" 
                  item-text="bodega_nombre" 
                  label="A Bodega" 
                  :rules="paquetesRules" 
                  @change="controlBodegas()" 
                  dense
                  hide-details
                  return-object
                  outlined
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="8" class="pb-3 pt-0">
                <v-autocomplete 
                  v-model="selectedTipoPaquete" 
                  :items="tipoPaquetes" 
                  item-text="tipo_descripcion" 
                  label="Tipo de paquete" 
                  :rules="paquetesRules" 
                  dense
                  hide-details
                  return-object
                  outlined
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="12">
                <v-textarea v-model="observacion" outlined clearable counter :rows="2" label="Observacion" :rules="[rulesObesrvacion.required, rulesObesrvacion.length(100)]" dense></v-textarea>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :color="$vuetify.theme.dark ?  'orange' : 'secondary' " @click="cerrarModal">Cancelar</v-btn>
          <v-btn color="success" @click="confirmarPaquete">Grabar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
//import BtnConfirmar from '../../util/BtnConfirmar.vue'

export default {
  name: 'ModalAddPaquete',
  props: {
    bodegasOrig: {
      type: Array,
      default: []
    },
    bodegasDest: {
      type: Array,
      default: []
    },
    tiposPaquetes: {
      type: Array,
      default: []
    },
    dialogActivo: {
      type: Boolean,
      default: false
    },
    prov: {
      type: Array,
      default: []
    }
  },
  data() {
    return {
      cliente: '',
      documento: '',
      telefono: '',
      direccion: '',
      paquetesRules: [
        v => !!v || 'Campo obligatorio.',
      ],
      rulesObesrvacion: {
        length: len => v => (v || '').length <= len || `Cantidad máxima de ${len} caracteres.`,
        required: v => !!v || 'Campo obligatorio.'
      },
      observacion: '',
      selectedProvincia: {},
      selectedLocalidad: {},
      selectedBodegaOrigen: {},
      selectedBodegaDestino: {},
      selectedTipoPaquete: {},
      formPaquete: false,
      localidades: [],
      pantallaChica: this.$vuetify.breakpoint.xs,
    }
  },
  computed: {
    activo: {
      get(){
        return this.dialogActivo
      },
      set(value){
        this.$emit('setearModalNewPaquete', value)
      }
    },
    bodegasD: {
      get(){
        return this.bodegasOrig
      }
    },
    bodegasA: {
      get(){
        return this.bodegasDest
      }
    },
    tipoPaquetes: {
      get(){
        return this.tiposPaquetes
      }
    },
    provincias: {
      get(){
        return this.prov
      }
    }
  },
  methods: {
    controlBodegas(){
      if (this.selectedBodegaOrigen && this.selectedBodegaDestino && Object.keys(this.selectedBodegaDestino).length > 0 && Object.keys(this.selectedBodegaOrigen).length > 0){
        if (this.selectedBodegaDestino.bodega_codigo == this.selectedBodegaOrigen.bodega_codigo){
          this.$store.dispatch('show_snackbar', {text: 'Las Bodega de Origen y Destino no pueden ser las mismas.', color: 'info'})
          this.selectedBodegaDestino = {}
          this.selectedBodegaOrigen = {}
        }
      }
    },
    camposValidos(){
      if (Object.keys(this.selectedProvincia).length == 0){
        this.$store.dispatch('show_snackbar', {text: 'Por favor, ingrese la Provincia.', color: 'warning'})
        return false
      }
      if (Object.keys(this.selectedLocalidad).length == 0){
        this.$store.dispatch('show_snackbar', {text: 'Por favor, ingrese la Localidad.', color: 'warning'})
        return false
      }
      if (Object.keys(this.selectedBodegaOrigen).length == 0){
        this.$store.dispatch('show_snackbar', {text: 'Por favor, ingrese la Bodega Origen.', color: 'warning'})
        return false
      }
      if (Object.keys(this.selectedBodegaDestino).length == 0){
        this.$store.dispatch('show_snackbar', {text: 'Por favor, ingrese la Bodega Destino.', color: 'warning'})
        return false
      }
      if (Object.keys(this.selectedTipoPaquete).length == 0){
        this.$store.dispatch('show_snackbar', {text: 'Por favor, ingrese el Tipo de Paquete.', color: 'warning'})
        return false
      }

      return true
    },
    async confirmarPaquete(){
      if(!this.$refs.formPaquetes.validate()){
        return
      }
      if (!this.camposValidos()){
        return
      }
      // armo el body para generar el paquete
      let body = {
        deBodega: this.selectedBodegaOrigen.bodega_codigo,
        aBodega: this.selectedBodegaDestino.bodega_codigo,
        localidad: this.selectedLocalidad.localidad_id,
        localidad_des: this.selectedLocalidad.descripcion_larga,
        provincia: this.selectedProvincia.provincia_id,
        direccion: this.direccion,
        telefono: this.telefono,
        documento: this.documento,
        cliente: this.cliente,
        observacion: this.observacion,
        codigo_postal: this.selectedLocalidad.codigo_postal,
        tipopaquete: this.selectedTipoPaquete.tipo_id
      }
      this.$emit('confimPackage', body)
    },
    async getlocalidad(){
      if(this.selectedProvincia){
        this.$store.state.loading = true;
        let localidadesPeticion   = await this.$store.dispatch('paquetes/getLocalidades', {idProvincia: this.selectedProvincia.provincia_id})
        this.$store.state.loading = false;
        if (localidadesPeticion.resultado != 1){
          return this.$store.dispatch('show_snackbar', {text: localidadesPeticion.msj, color: 'error'})
        }
        this.localidades = localidadesPeticion.localidades
      }else{
        this.selectedLocalidad = {};
        this.localidades       = [];
      }
    },
    cerrarModal(){
      this.cliente = ''
      this.documento = ''
      this.telefono = ''
      this.direccion = ''
      this.selectedProvincia = {}
      this.selectedLocalidad = {}
      this.selectedBodegaOrigen = {}
      this.selectedBodegaDestino = {}
      this.selectedTipoPaquete = {}
      this.observacion = ''
      if (this.activo == true){
        this.activo = false
      }
    }
  },
  components:{
    //BtnConfirmar
  },
  watch: {
    activo: function(){
      if (this.activo == false){
        this.cerrarModal()
      }
    },
    '$vuetify.breakpoint.xs'(val){
      this.pantallaChica = val;
    },
  },
}
</script>

<style>

</style>