<template>
  <v-dialog
    v-model="activo"
    width="700"
  >
    <v-card flat>
      <v-card-title class="" height="15%">
        <span class="">Aceptar Paquete</span>
        <v-spacer></v-spacer>
        <v-btn icon @click="activo = false">
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-4 pb-0">
        <v-row>
          <v-col cols="12" sm="12">
            <v-textarea
              v-model="observacion"
              outlined
              dense
              label="Ingrese la observación (opcional)."
            ></v-textarea>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="pt-0">
        <v-spacer></v-spacer>
        <v-btn :color="$vuetify.theme.dark ?  'orange' : 'secondary' " @click="cerrarModal()">Cancelar</v-btn>
        <v-btn color="success" @click="aceptPaquete()">Aceptar Paquete</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ModalAcepPaquete',
  props: {
    dialogActivo: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    activo: {
      get(){
        return this.dialogActivo
      },
      set(value){
        this.$emit('setearModalAceptPaq', value)
      }
    },
  },
  data() {
    return {
      observacion: '',
      pantallaChica: this.$vuetify.breakpoint.xs,
    }
  },
  methods: {
    cerrarModal(){
      this.activo = false
      this.observacion = ''
    },
    aceptPaquete(){
      this.$emit('aceptarPaq', {aceptar: true, observacion: this.observacion})
      this.cerrarModal()
    }
  },
  watch:{
    '$vuetify.breakpoint.xs'(val){
      this.pantallaChica = val;
    },
  }
}
</script>

<style>

</style>