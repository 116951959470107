<template>
  <div>
    <v-dialog v-model="activo" persistent width="700">
      <v-card>
        <v-card-title class="">
          <span class=""> <v-icon class="mr-2" >fas fa-truck</v-icon> Generar Lote</span>
          <v-spacer></v-spacer>
          <v-btn icon @click="activo = false">
            <v-icon>fas fa-times</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-4 pb-2">
          <v-form ref="formLote" v-model="formLote" @submit.prevent="grabarLote" class="pa-1">
            <v-row>
              <v-col cols="12" sm="6" md="6" class="py-2">
                De Bodega
                <v-autocomplete 
                  class="pa-0"
                  v-model="selectedBodegaOrigen" 
                  :items="bodegasD" 
                  :rules="loteRules" 
                  item-text="bodega_nombre"
                  @change="controlBodega()" 
                  dense
                  hide-details
                  return-object
                  outlined
                ></v-autocomplete>
              </v-col>
              <v-col cols="10" sm="5" md="5" class="py-2">
                A Bodega
                <v-autocomplete 
                  v-model="selectedBodegaDestino" 
                  :items="bodegasA" 
                  :rules="loteRules" 
                  item-text="bodega_nombre"
                  @change="controlBodega()" 
                  dense
                  hide-details
                  return-object
                  outlined
                ></v-autocomplete>
              </v-col>
              <v-col cols="2" sm="1" md="1" class="py-2" :class="pantallaChica ? 'd-flex justify-end' : 'd-flex justify-center'" >
                <v-btn color="primary" class="mt-4" @click="getPaquetes()" fab dark title="Cargar Paquetes" small><v-icon small>fas fa-download</v-icon></v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="7" md="7" :class="pantallaChica? 'py-2' : 'py-0'">
                Fecha
                <FechaPickerRango
                  v-model="fechaRango"
                  :limpiar.sync="limpiarFecha"
                />
              </v-col>
              <v-col cols="12" sm="6" md="5" :class="pantallaChica? 'py-2' : 'py-0'">
                Repartidor
                <v-autocomplete 
                  v-model="selectedRepartidor" 
                  :items="listRepartidores" 
                  :rules="loteRules" 
                  item-text="repartidor_nombre"
                  dense
                  hide-details
                  return-object
                  outlined
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row class="">
              <v-col cols="12" sm="12" md="12">
                <v-data-table
                  class="cebra elevation-2 mt-2"
                  :headers="header"
                  :items="paquetes"
                  :loading="load"
                  dense
                  :search="search"
                >
                  <!-- Buscador en el datatable -->
                  <template v-slot:top>
                    <v-row class="d-flex justify-end pa-2" no-gutters>
                      <v-col cols="6" sm="3">
                        <SearchDataTableVue
                          v-model="search"
                        />
                      </v-col>
                    </v-row>
                  </template>
                  <!-- Acciones -->
                  <template v-slot:[`item.accion`]="{ item }">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn small icon color="error" @click="eliminardet(item)" v-on="on">
                          <v-icon class="mr-2" >fas fa-times-circle</v-icon>
                        </v-btn>
                      </template>
                      <span>Eliminar</span>
                    </v-tooltip>
                  </template>
                  <template v-slot:no-data>
                    <v-alert
                      class="mx-auto mt-4"
                      max-width="400"
                      type="warning"
                      border="left"
                      dense
                      text
                    >
                      No hay datos para los filtros seleccionados
                    </v-alert>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <div class="d-flex">
            <v-btn :color="$vuetify.theme.dark ?  'orange' : 'secondary' "  class="mr-1" @click="cerrarModal">Cancelar</v-btn>
            <v-btn @click="grabarLote()" class="" color="success">Grabar</v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import FechaPickerRango from '../../util/FechaPickerRango.vue'
import SearchDataTableVue from '../../util/SearchDataTable.vue'
import moment from 'moment'

export default {
  name: 'ModalGenerarLote',
  props: {
    dialogActivo: {
      type: Boolean,
      default: false
    },
    bodegasOrig: {
      type: Array,
      default: []
    },
    bodegasDest: {
      type: Array,
      default: []
    },
    repartidores: {
      type: Array,
      default: []
    }
  },
  data() {
    return {
      loteRules: [
        v => !!v || 'Campo obligatorio.',
      ],
      formLote: false,
      selectedBodegaOrigen: {},
      selectedBodegaDestino: {},
      selectedRepartidor: {},
      paquetes: [],
      fechaRango: [ null, null ],
      search: '',
      load: false,
      limpiarFecha: false,
      pantallaChica: this.$vuetify.breakpoint.xs,
      header:[
        {text: 'Código', value: 'paquete_id'},
        {text: 'Cliente', value: 'destinatario_nombre'},
        {text: 'DNI', value: 'destinatario_nro_doc'},
        {text: 'Dirección', value: 'destinatario_domicilio'},
        {text: 'Localidad', value: 'destinatario_localidad'},
        {text: 'Acción', value: 'accion'}
      ],
    }
  },
  components: {
    FechaPickerRango, SearchDataTableVue
  },
  computed: {
    activo: {
      get(){
        return this.dialogActivo
      },
      set(value){
        this.$emit('setearModalAddLote', value)
      }
    },
    listRepartidores: {
      get(){
        return this.repartidores
      }
    },
    bodegasA: {
      get(){
        return this.bodegasDest
      }
    },
    bodegasD: {
      get(){
        return this.bodegasOrig
      }
    }
  },
  methods: {
    async getPaquetes(){
      if (Object.keys(this.selectedBodegaOrigen).length == 0){
        return this.$store.dispatch('show_snackbar', {text: 'Debe seleccionar la Bodega de Origen para proceder.', color: 'warning'})
      }
      this.paquetes = []
      // fechas
      let fechaDesde = moment(this.fechaRango[0]).format('DD/MM/YYYY')
      let fechaHasta = ''
      if (this.fechaRango[1].length > 0){
        fechaHasta = moment(this.fechaRango[1]).format('DD/MM/YYYY')
      }
      // estados
      let estadoPquete = [1]
      let estadoMov = [4, 6]
      // genero el body para la consulta
      let body = {
        fechaDesde: fechaDesde,
        fechaHasta: fechaHasta,
        tipoEnvio: 0,
        deBodega: this.selectedBodegaOrigen.bodega_codigo,
        estadoPquete: estadoPquete,
        usuario: this.$store.state.username,
        estadoMov: estadoMov,
        repartidor_id: 0
      }
      this.load = true;
      this.$store.state.loading = true;
      let paquetesPeticion = await this.$store.dispatch('paquetes/getPaquetes', body)
      this.$store.state.loading = false;
      this.load = false;
      if (paquetesPeticion.resultado != 1){
        return this.$store.dispatch('show_snackbar', {text: 'Debe seleccionar la Bodega de Origen para proceder.', color: 'warning'})
      }
      this.paquetes = paquetesPeticion.paquetes
    },
    eliminardet(detalle){
      let pos = this.paquetes.indexOf(detalle)
      this.paquetes.splice(pos, 1)
    },
    limpiarCampos(){
      this.selectedBodegaDestino = {}
      this.selectedBodegaOrigen = {}
      this.selectedRepartidor = {}
      this.paquetes = []
      this.limpiarFecha = true
    },
    cerrarModal(){
      this.limpiarCampos()
      if (this.activo == true){
        this.activo = false
      }
    },
    controlBodega(){
      if (this.selectedBodegaDestino && this.selectedBodegaOrigen && Object.keys(this.selectedBodegaDestino).length > 0 && Object.keys(this.selectedBodegaOrigen).length > 0){
        if (this.selectedBodegaDestino.bodega_codigo == this.selectedBodegaOrigen.bodega_codigo){
          this.limpiarCampos()
          return this.$store.dispatch('show_snackbar', {text: 'Las bodegas de origen y destino no pueden ser las mismas.', color: 'warning'})
        }
      }
    },
    camposValidos(){
      if (Object.keys(this.selectedBodegaOrigen).length == 0){
        this.$store.dispatch('show_snackbar', {text: 'Debe ingresar la Bodega de Origen.', color: 'warning'})
        return false
      }
      if (Object.keys(this.selectedBodegaDestino).length == 0){
        this.$store.dispatch('show_snackbar', {text: 'Debe ingresar la Bodega de Destino.', color: 'warning'})
        return false
      }
      if (Object.keys(this.selectedRepartidor).length == 0){
        this.$store.dispatch('show_snackbar', {text: 'Debe ingresar el repartidor.', color: 'warning'})
        return false
      }
      if(this.paquetes.length == 0){
        this.$store.dispatch('show_snackbar', {text: 'Es necesario agregar paquetes para grabar un Lote.', color: 'warning'})
        return false
      }
      return true
    },
    async grabarLote(value){
      if(!this.$refs.formLote.validate()){
        return
      }
      if (!this.camposValidos()){
        return
      }
      // armo el body que voy a mandar a la API
      let body = {
        bodega_origen: this.selectedBodegaOrigen.bodega_codigo,
        bodega_destino: this.selectedBodegaDestino.bodega_codigo,
        repartidor_id: this.selectedRepartidor.repartidor_codigo,
        paquetes: this.paquetes
      }
      this.$emit('confirmLote', body)
    }
  },
  watch: {
    paquetes: function(){
      if (this.paquetes.length > 0){
        for (let id in this.paquetes){
          this.paquetes[id].fecha_log = moment(this.paquetes[id].fecha_log).format('DD/MM/YYYY')
        }
      }
    },
    activo: function(){
      if (this.activo == false){
        this.cerrarModal()
      }
    },
    '$vuetify.breakpoint.xs'(val){
      this.pantallaChica = val;
    },
  },
}
</script>

<style>

</style>